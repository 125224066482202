<template>
  <div class="paymentCycle">
    <div v-for="(item, index) in data" :key="index">
      <el-checkbox v-model="item.isChecked" @change="handleCheckAllChange($event, item.children)">
        合同编号：{{ item.label }}
      </el-checkbox>
      <div class="content">
        <div class="list" v-for="(list, j) in item.children" :key="j">
          <el-checkbox :label="list.id" v-model="list.isChecked" @change="isCheckedChange($event, list, item)">
            <div class="item">缴费周期：{{ list.soData.property_time }} - {{ list.soData.property_end_time }}</div>
            <div class="item" v-if="list.soData.bill_key == 10">商铺号：{{ list.soData.merchants_stall_number }}</div>
            <div class="item">约定缴费时间：{{ list.soData.confirm_time }}</div>
            <div class="item">缴费金额：{{ list.soData.key_pay_price }}</div>
            <div class="item" style="color: #F56C6C;" v-if="list.soData.bill_key == 10 && list.soData.derateTotal">
              周期减免：{{ list.soData.derateTotal }}</div>
            <div class="item" v-if="list.soData.bill_key == 10 && getDiscountShow(list)">
              <el-checkbox :disabled="!list.isChecked" v-model="list.soData.isDiscount"
                @change="discountChange($event, list, item)">
                折扣：{{ list.soData.propertySetting.ActivityDiscount * 10 }}折
                <span v-if="list.soData.isDiscount">折扣减免: {{ list.soData.discountPrice }}</span>
              </el-checkbox>
            </div>
            <div class="item" style="color: #F56C6C;" v-if="list.soData.bill_key == 10">应缴金额：
              {{ list.soData.pay_amount || 0 }}
            </div>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'paymentCycle',
  props: {
    data: {
      type: Array,
      default () {
        return {};
      }
    }
  },
  data () {
    return {
      checkedCities: []
    }
  },
  methods: {
    handleCheckAllChange (e, item) {
      if (e) {
        item.forEach((list) => {
          list.isChecked = true;
        });
      } else {
        item.forEach((list) => {
          list.isChecked = false;
        });
      }
      this.$emit('calculateDiscount');
      this.$forceUpdate();
    },
    isCheckedChange (e, list, item) {
      this.$set(list, 'isChecked', e);
      let price = Number(list.soData.key_pay_price) - Number(list.soData.derateTotal || 0) - Number(list.soData?.discountPrice || 0);
      this.$set(list.soData, 'pay_amount', Number(parseFloat(price).toFixed(2)));
      let is = true;
      item.children.forEach((i) => {
        if (!i.isChecked) {
          is = false;
        }
      })
      item.isChecked = is;
      this.$emit('calculateDiscount');
      this.$forceUpdate();
    },
    discountChange (e, list, item) {
      let discount = (Number(list.soData.key_pay_price) - Number(list.soData.derateTotal || 0)) * list.soData.propertySetting.ActivityDiscount;
      let price = Number(parseFloat((Number(list.soData.key_pay_price) - Number(list.soData.derateTotal || 0)) - Number(discount.toFixed(2))).toFixed(2));
      let total = 0;
      if (!e) {
        total = Number(list.soData.key_pay_price) - Number(list.soData.derateTotal || 0);
      } else {
        total = Number(list.soData.key_pay_price) - Number(list.soData.derateTotal || 0) - Number(price || 0);
      }
      this.$set(list.soData, 'pay_amount', Number(parseFloat(total).toFixed(2)));
      this.$set(list.soData, 'discountPrice', e ? Number(parseFloat(price).toFixed(2)) : 0);
      this.$set(list.soData, 'isDiscount', e);
      this.$emit('calculateDiscount');
      this.$forceUpdate();
    },
    getDiscountShow (item) {
      if (item.soData.propertySetting) {
        let time = new Date().getTime();
        let start = new Date(item.soData.propertySetting.ActivityStartTime).getTime();
        let end = new Date(item.soData.propertySetting.ActivityEndTime).getTime();
        if (time <= end && time >= start) {
          return true;
        }
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.paymentCycle {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .list {
      width: 23%;
      padding-left: 20px;
      margin-bottom: 20px;

      .el-checkbox {
        display: flex;
        align-items: center;
      }

      .item {
        margin-bottom: 5px;
      }
    }
  }
}
</style>