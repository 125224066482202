<template>
  <div class="addCharge">
    <Navlid :list="navlist"></Navlid>
    <div class="public-box">
      <el-form label-position="right" label-width="150px">
        <el-form-item label="商户姓名" required>
          <el-select class="public-input" v-model="form.merchants_id" filterable @change="merchantsChange"
            placeholder="请选择商户姓名">
            <el-option v-for="item in merchantsList" :key="item.merchants_id" :value="item.merchants_id"
              :label="item.contact_name"></el-option>
          </el-select>
          <span style="margin-left: 15px">
            <el-checkbox v-model="tempMerchant" @change="tempMerchantChange">临时收费</el-checkbox>
          </span>
          <div style="color: #F56C6C;">
            <p>注: 折扣金额=（缴费金额-周期减免）/ 折扣 折扣金额在每一期中单独计算</p>
            <p>应缴金额=缴费金额-周期减免-折扣金额-余额抵扣</p>
            <p>当选择了多个缴费周期时，每一个周期的（缴费金额-周期减免-折扣金额）相加后再减特殊减免和余额抵扣</p>
          </div>
        </el-form-item>
        <el-form-item label="商铺" v-show="tempMerchant">
          <el-select v-model="merchants_stall_id" class="public-input" @change="merchantsStallChange" filterable
            placeholder="请选择商铺">
            <el-option v-for="item in allShop" :key="item.merchants_stall_id" :label="item.merchants_stall_number"
              :value="item.merchants_stall_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input class="public-input" placeholder="请选择商户" v-model="form.merchants_card_id" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="缴费类型" v-if="form.merchants_id" required>
          <el-checkbox-group v-model="paymentType" @change="changePaymentType($event)">
            <el-checkbox :label="index" v-for="(item, index) in paymentTypeList" :disabled="item.disabled" :key="index">{{
              item.contract_fee_name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="">
          <el-collapse :value="opened">
            <el-collapse-item v-for="(item, index) in selectTypeList" :key="item.id" :name="item.id">
              <template slot="title">
                {{ item.contract_fee_name }}
              </template>
              <template v-if="!tempMerchant">
                <paymentCycle :data="selectTypeListDataObject[item.id]?.treeData"
                  @calculateDiscount="calculateDiscount(selectTypeListDataObject[item.id])"></paymentCycle>
                <div v-if="selectTypeListDataObject[item.id].bill_key == 10">
                  <span>剩余减免：{{ selectTypeListDataObject[item.id]?.couponTotal }}</span>
                  <span style="marginLeft: 10px;">使用减免：{{ selectTypeListDataObject[item.id]?.couponTotalPrice }}</span>
                </div>
                <div v-if="selectTypeListDataObject[item.id].bill_key == 10">
                  折扣减免：{{ Number(selectTypeListDataObject[item.id]?.zhekoPrice) }}
                </div>
                <div v-if="selectTypeListDataObject[item.id].bill_key == 10">
                  <span>余额:{{ selectTypeListDataObject[item.id]?.merchants_balance?.balance || 0 }}</span>
                  <span style="marginLeft: 10px;">余额抵扣：{{ selectTypeListDataObject[item.id]?.merchants_balance?.actBalance
                  }}</span>
                </div>
                <div>
                  应缴金额：{{ selectTypeListDataObject[item.id]?.payableMoney }}
                </div>
                <div>
                  实缴：<input class="ac_input" aria-placeholder="实缴金额" type="number" :disabled="item.moneyDislabel"
                    v-model.trim="selectTypeListDataObject[item.id].lastMoney" @change="moneyChange" />
                </div>
              </template>
              <template v-else>
                <div>
                  实缴：<input aria-placeholder="实缴金额" type="number"
                    v-model.trim="sekectTypeListTempMerObj[item.id].lastMoney" @change="moneyChange1(
                      sekectTypeListTempMerObj[item.id].lastMoney,
                      item.id
                    )
                      " />
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <el-form-item label="应缴总金额">
          {{ yjiaoPrice }}
          <el-button type="primary" @click="goMo">抹零{{
            moLen == 3
            ? "(元)"
            : moLen == 2
              ? "(角)"
              : moLen == 1
                ? "(分)"
                : ""
          }}</el-button>
        </el-form-item>
        <el-form-item label="抹零金额">
          {{ deleMoney }}
        </el-form-item>
        <el-form-item label="实缴总金额">
          {{ lastPlayMoney }}
        </el-form-item>
        <el-form-item label="收款方式">
          <el-radio-group v-model="subData.pay_type" @change="changePayType">
            <el-radio :label="30">现金</el-radio>
            <el-radio :label="31">扫码</el-radio>
            <el-radio :label="32">扫码枪</el-radio>
            <el-radio :label="33">刷卡</el-radio>
            <el-radio :label="34">对公转账</el-radio>
            <el-radio :label="35">现金补录</el-radio>
            <el-radio :label="36">扫码补录</el-radio>
            <el-radio :label="37">扫码枪补录</el-radio>
            <el-radio :label="38">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收款时间" v-if="[30, 31, 32, 33, 34].indexOf(subData.pay_type) == -1">
          <el-date-picker v-model="subData.pay_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="凭证">
          <div class="upload-imgx-box" v-for="(item, index) in images" :key="index">
            <img class="upload-img" :src="item" alt="" />
            <span @click="deleteImg(index)">x</span>
          </div>
          <el-upload action="" :auto-upload="false" :show-file-list="false" :on-change="filechange">
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
            v-model.trim="subData.remarks"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="btnDisabled" type="primary" @click="submitUp">确认</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog title="收款码" :visible.sync="getMoney" :close-on-click-modal="false" :show-close="false" width="400px">
      <div id="qrcode" style="margin: auto; width: 300px; height: 300px"></div>
      <el-button type="info" @click="getMoneyClose" style="margin: 40px auto 0 155px">关闭</el-button>
    </el-dialog>
    <input ref="scanMoney" v-model="qrcode" style="
        border: 1px solid red;
        position: fixed;
        left: 99999px;
        top: -9999px;" />
    <div class="mask" v-if="qrpay">
      <div class="qrclose" v-if="!isScan" @click="closeqrmask">
        <i class="el-icon-close"></i>
      </div>
      <div v-if="!isScan">
        <img src="../../../assets/gun.png" alt />
        <div>请出示付款码</div>
      </div>
      <div v-else style="display: contents">
        <i class="el-icon-loading"></i>
        <div>等待收款</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reserveDecimalFour } from "../../../plugin/common";
import QRCode from "qrcodejs2";
import { debounceA } from "../../../unit/debounce";
import paymentCycle from './paymentCycle.vue';

export default {
  name: "addCharge",
  components: {
    paymentCycle
  },
  data () {
    return {
      getMoney: false,
      checkList: [1],
      navlist: [
        {
          title: "线下收费",
          url: "/charge/chargeoffline",
        },
        {
          title: "新建收费",
        },
      ],
      btnDisabled: false,
      images: [],
      merchantsList: [],
      tempMerchant: false,
      form: {
        merchants_id: "",
        merchants_card_id: "",
        bill_ids: [],
      },
      subData: {
        merchants_id: "",
        remarks: "",
        pay_type: 30,
        gong_voucher: "",
        payment_money: "",
        pay_time: '',
        zero_price: 0,
        bill_type: 10,
        fee_setting_list: [
          {
            contract_fee_id: "",
            contract_fee_name: "",
            bill_ids: "",
            bill_key: "",
            balance_price: "",
            coupon_ids: "",
            coupon_price: "",
            coupon_total_price: "",
            activity_discount: "",
            give_discount: "",
            much_balance_price: "",
            payment_money: "",
          },
        ],
      },
      dayFeeList: [],
      merchants_stall_id: "",
      merchants_stall_number: "",
      paymentType: [],
      paymentTypeList: [],
      selectTypeListIndex: [],
      selectTypeListDataObject: {},
      sekectTypeListTempMerObj: {},
      selectTypeList: [],
      lastPlayMoney: 0,
      yjiaoPrice: 0,
      deleMoney: 0,
      moLen: "",
      curreNodeCheck: true,
      lastSelect: [],
      allShop: null,
      timer: null,
      qrpay: false,
      isScan: false,
      qrcode: "",
      timeN: null,
    };
  },
  computed: {
    opened () {
      return this.selectTypeList.map((i) => {
        return i.id;
      });
    }
  },
  created () {
    // this.subData.pay_time = this.$common.formatDateTimeHms(new Date());
    this.getMerchantList();
    this.getPaymentType();
    this.getShop();
    this.cashWipe();
  },
  watch: {
    qrcode (val) {
      if (val.length > 0) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.scanPay(val);
        }, 1000);
      }
    },
  },
  beforeDestroy () {
    clearInterval(this.timeN);
    clearTimeout(this.timer);
  },
  methods: {
    changePayType (e) {
      this.subData.pay_time = '';
    },
    calculateDiscount (data) {
      let total = 0;
      let totalPrice = 0;
      data.treeData.forEach((item) => {
        if (item.children) {
          item.children.forEach((list) => {
            if (list.isChecked) {
              total += Number(list.soData.discountPrice || 0);
              totalPrice += Number(list.soData.pay_amount || 0)
            }
          });
        }
      })
      if (totalPrice) {
        // 判断剩余减免
        if (data.couponTotal) {
          if (totalPrice >= data.couponTotal) {
            data.couponTotalPrice = data.couponTotal;
          } else {
            data.couponTotalPrice = totalPrice < 0 ? 0 : totalPrice;
          }
        } else {
          data.couponTotalPrice = 0;
        }
        // 计算余额抵扣
        if (data?.merchants_balance?.balance) {
          if (totalPrice - data.couponTotal > data.merchants_balance.balance) {
            data.merchants_balance.actBalance = data.merchants_balance.balance;
          } else {
            data.merchants_balance.actBalance = totalPrice - data.couponTotal < 0 ? 0 : totalPrice - data.couponTotal;
          }
        } else {
          data.merchants_balance.actBalance = 0;
        }
      } else {
        data.couponTotalPrice = 0;
        data.merchants_balance.actBalance = 0;
      }
      data.zhekoPrice = Number(parseFloat(total).toFixed(2));
      data.total = Number(parseFloat(totalPrice).toFixed(2));
      data.lastMoney = totalPrice < 0 ? 0 : Number(parseFloat(totalPrice).toFixed(2));
      data.payableMoney = totalPrice < 0 ? 0 : Number(parseFloat(totalPrice).toFixed(2));
      // this.initAllMoney(data, data.total);
      this.calculateItemTotal(data);
      this.getPlayTotal();
      this.$forceUpdate();
    },
    calculateItemTotal (data) {
      let total = Number((Number(data.total) - Number(data.couponTotal) - Number(data.merchants_balance.actBalance)).toFixed(2));
      data.lastMoney = total < 0 ? 0 : total;
      data.payableMoney = total < 0 ? 0 : total;
    },
    closeqrmask () {
      this.qrpay = false;
      this.qrcode = "";
      this.isScan = false;
      this.btnDisabled = false;
      clearInterval(this.timeN);
      clearTimeout(this.timer);
    },
    getMoneyClose () {
      this.$confirm("确认关闭弹窗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getMoney = false;
          clearInterval(this.timeN);
        })
        .catch(() => { });
    },
    cashWipe () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "wipeZero",
        })
        .then((res) => {
          this.moLen = res.data?.values?.unit || "";
        });
    },
    merchantsStallChange (e) {
      console.log("merchants_stall_number", e);
      let data = this.allShop.find((item) => e == item.merchants_stall_id);
      this.merchants_stall_number = data?.merchants_stall_number || "";
    },
    getShop () {
      this.$request
        .HttpGet("/contract/stallList", {
          pageNum: 1,
          pageSize: 9999,
        })
        .then((res) => {
          this.allShop = res.data?.list || null;
        });
    },
    tempMerchantChange (ev) {
      this.tempMerchant = ev;
      this.paymentType = [];
      this.selectTypeList = [];
      this.selectTypeListIndex = [];
      this.lastPlayMoney = 0;
      this.yjiaoPrice = 0;
      this.deleMoney = 0;
      this.paymentTypeList.forEach((item) => {
        item.disabled = false;
        if (this.dayFeeList.includes(item.id) && this.tempMerchant) {
          item.disabled = true;
        }
      });
      console.log(this.selectTypeListDataObject);
    },
    // 支付结果
    getPayResult (payNo) {
      let getTimer = setInterval(() => {
        this.$request
          .HttpPost("/bill_offline_v2/barcodeGunSucceed", {
            payNo,
          })
          .then((res) => {
            if (res.data) {
              if (res.data.code == 0) {
                clearInterval(getTimer);
                this.closeqrmask();
                this.$message({
                  message: "支付成功",
                  type: "success",
                });
                this.qrcode = "";
                this.$request
                  .HttpPost("/bill_offline_v2/barcodeGunReturn", {
                    orderNo: payNo,
                    status: 1,
                  })
                  .then(() => {
                    this.$router.push("/charge/chargeoffline");
                  });
              } else if (res.data.code == -2) {
                clearInterval(getTimer);
                this.closeqrmask();
                this.$message({
                  message: "支付失败",
                  type: "error",
                });
              }
            } else {
              clearInterval(getTimer);
              this.closeqrmask();
              this.$message({
                message: "支付失败",
                type: "error",
              });
            }
          });
      }, 5000);
    },
    scanPay (code) {
      if (code) {
        this.isScan = true;
        this.$request
          .HttpPost("/bill_offline_v2/billOfflineV2", {
            ...this.subData,
            qrcode: code,
          })
          .then((res) => {
            if (res.data) {
              this.getPayResult(res.data.payment_url.payNo);
            }
          })
          .finally(() => { });
      }
    },
    submitUp: debounceA(
      function () {
        if (!this.form.merchants_id) {
          this.$message({
            message: "请选择商户",
            type: "warning",
          });
          return false;
        }
        if (!this.paymentType.length || this.paymentType.length == 0) {
          this.$message({
            message: "请选择缴费类型",
            type: "warning",
          });
          return false;
        }
        if ([35, 36, 37, 38].indexOf(this.subData.pay_type) != -1 && !this.subData.pay_time) {
          this.$message({
            message: "请选择缴费时间",
            type: "warning",
          });
          return false;
        }
        this.subData.gong_voucher = JSON.stringify(this.images);
        this.subData.payment_money = this.lastPlayMoney * 1;
        this.subData.zero_price = this.deleMoney * 1;
        this.subData.merchants_id = this.form.merchants_id;
        this.subData.merchants_stall_number = this.merchants_stall_number;
        this.subData.merchants_stall_id = this.merchants_stall_id;
        if (this.tempMerchant) {
          this.subData.bill_key = 8;
        }
        let arr = [];
        if (this.tempMerchant) {
          this.selectTypeList.forEach((item) => {
            arr.push({
              contract_fee_id: item.id,
              contract_fee_name: item.contract_fee_name,
              bill_ids: [],
              bill_key: this.sekectTypeListTempMerObj[item.id].bill_key,
              balance_price: 0,
              coupon_ids: [],
              coupon_price: 0,
              coupon_total_price: 0,
              activity_discount: "",
              give_discount: 0,
              much_balance_price: 0,
              payment_money: this.sekectTypeListTempMerObj[item.id].lastMoney * 1
            });
          });
        } else {
          for (let i in this.selectTypeListDataObject) {
            let item = this.selectTypeListDataObject[i].treeData;
            let tempArr = {
              children: [],
              contract_fee_id: this.selectTypeListDataObject[i].fee_id,
              payment_money: this.selectTypeListDataObject[i].lastMoney,
              balance_price: this.selectTypeListDataObject[i]?.merchants_balance?.actBalance || 0,
              give_discount: this.selectTypeListDataObject[i].zhekoPrice,
              coupon_total: this.selectTypeListDataObject[i].couponTotal,
              coupon_used: this.selectTypeListDataObject[i].couponTotalPrice,
              balance_price_used: this.selectTypeListDataObject[i]?.merchants_balance?.actBalance || 0,
              coupon_id: this.selectTypeListDataObject[i].coupon_ids,
              payable: this.selectTypeListDataObject[i].payableMoney
            }
            item.forEach((list) => {
              tempArr.contract_fee_name = list.soData.bill_name,
                list.children.forEach((data) => {
                  if (data.isChecked) {
                    tempArr.children.push({
                      contract_id: list.soData.contract_id,
                      contract_no: list.soData.contract_no,
                      bill_id: data.soData.bill_id,
                      merchants_stall_number: data.soData.merchants_stall_number,
                      key_pay_price: data.soData.key_pay_price,
                      derate_price: data.soData?.derateTotal || 0,
                      discount: data.soData?.propertySetting?.ActivityDiscount || 1,
                      discount_price: data.soData?.discountPrice || 0,
                      pay_amount: data.soData?.pay_amount || 0,
                      coupon_id: data.soData?.coupon_ids || []
                    })
                  }
                })
            });
            if (tempArr.children.length > 0) {
              arr.push(tempArr);
            }
          }
        }
        this.subData.fee_setting_list = arr;
        console.log(this.subData);
        this.btnDisabled = true;
        if (this.subData.pay_type == 32) {
          this.$refs.scanMoney.focus();
          this.qrpay = true;
          return false;
        }

        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$request.HttpPost("/bill_offline_v2/billOfflineV3", this.subData).then((res) => {
          this.mapSet(res);
        }).finally(() => {
          loading.close();
          this.btnDisabled = false;
        });
      }, 500, true
    ),
    mapSet (res) {
      if ([30, 33, 34, 35, 36, 37, 38].includes(this.subData.pay_type)) {
        this.$notify({
          title: "成功",
          message: res.msg,
          type: "success",
        });
        this.$router.push({ path: "chargeoffline" });
      }
      if (this.subData.pay_type == 31) {
        this.getMoney = true;
        this.$nextTick(function () {
          let ar = new QRCode(`qrcode`, {
            text: res.data.payment_url.url,
            width: 300,
            height: 300,
            colorDark: "#000000",
            colorLight: "#ffffff",
          });
          this.timeN = null;
          this.timeN = setInterval(() => {
            this.orderOfflinePayStatus(res.data.payment_url.payNo)
              .then((res) => {
                if (res.data.pay_status) {
                  this.$message({
                    message: "缴费成功",
                    type: "success",
                  });
                  clearInterval(this.timeN);
                  this.$router.push({ path: "chargeoffline" });
                }
              })
              .catch((err) => {
                this.$message({
                  message: "缴费失败",
                  type: "error",
                });
                clearInterval(this.timeN);
              });
          }, 1000);
        });
      }
    },
    orderOfflinePayStatus (code) {
      return this.$request.HttpPost("/bill_offline_v2/orderOfflinePayStatus", {
        order_no: code,
        status: 1,
      });
    },
    filechange (file, filelist) {
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.images.push(
          process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl
        );
      });
    },
    deleteImg (index) {
      this.images.splice(index, 1);
    },
    mo (value, len) {
      value = value.toString();
      if (value && len) {
        //抹分
        if (len == 1) {
          //58.36 ==>58.3
          let last = value.replace(/(\d+)(\.{1}\d{1}){1}\d*/gi, "$1$2");
          return last;
        }
        //抹角
        if (len == 2) {
          //53.63 ==> 53
          let last = value.replace(/(\d+)(\.{1}\d+){1}/gi, "$1");
          return last;
        }
        //抹元
        if (len == 3) {
          //58.2 ==> 50
          let last = value
            .replace(/(\d+)(\.{1}\d+){1}/gi, "$1")
            .replace(/(\d+)\d{1}/gi, "$10");
          return last;
        }
      } else {
        return "0.00";
      }
    },
    goMo () {
      this.lastPlayMoney = this.mo(this.yjiaoPrice, this.moLen);
      this.deleMoney = (this.yjiaoPrice - this.lastPlayMoney).toFixed(2);
    },
    getPlayTotal () {
      let total = 0;
      this.selectTypeList.forEach((item) => {
        total += this.selectTypeListDataObject[item.id].lastMoney * 1;
      });
      this.yjiaoPrice = total < 0 ? 0 : total.toFixed(2);
      this.lastPlayMoney = total < 0 ? 0 : total.toFixed(2);
    },
    getPlayTotal1 () {
      let total = 0;
      this.selectTypeList.forEach((item) => {
        total += this.sekectTypeListTempMerObj[item.id].lastMoney * 1;
      });
      this.yjiaoPrice = total.toFixed(2);
      this.lastPlayMoney = total.toFixed(2);
    },
    moneyChange () {
      this.getPlayTotal();
      this.selectTypeList.push("41");
      this.selectTypeList.pop();
    },
    moneyChange1 (money, id) {
      this.deleMoney = 0;
      if (
        money == "" ||
        money * 1 < this.sekectTypeListTempMerObj[id].lastMoneyCo * 1
      ) {
        this.$set(
          this.sekectTypeListTempMerObj[id],
          "lastMoney",
          this.sekectTypeListTempMerObj[id].lastMoneyCo
        );
      }
      this.getPlayTotal1();
      this.selectTypeList.push("41");
      this.selectTypeList.pop();
    },
    initAllMoney (sData, total) {
      sData.coupon_ids = [];
      // sData.couponTotalPrice = 0;
      // if (sData.treeData) {
      //   sData.treeData.forEach((item) => {
      //     item.children.forEach((list) => {
      //       if (list.soData.derate && list.isChecked) {
      //         sData.couponTotalPrice += list.soData.derate.reduce_price;
      //       }
      //       sData.coupon_ids.push(item.id);
      //     });
      //   })
      //   if (sData.couponTotalPrice > sData.couponTotal) {
      //     sData.couponTotalPrice = sData.couponTotal;
      //   }
      // }
      // 余额
      // actBalance 实际抵扣余额
      if (sData.merchants_balance) {
        sData.merchants_balance.actBalance = 0;
        if (sData.merchants_balance.balance > sData.lastMoney) {
          sData.merchants_balance.actBalance = sData.lastMoney;
        } else {
          sData.merchants_balance.actBalance =
            sData.merchants_balance.balance || 0;
        }
      } else {
        sData.merchants_balance = {};
        sData.merchants_balance.actBalance = 0;
      }
      sData.lastMoney -= sData.merchants_balance.actBalance;
      sData.lastMoney = sData.lastMoney < 0 ? 0 : sData.lastMoney.toFixed(2);
      sData.payableMoney = sData.lastMoney < 0 ? 0 : sData.lastMoney;
      sData.lastMoneyCo = sData.lastMoney < 0 ? 0 : sData.lastMoney;
    },
    merchantsChange (id) {
      this.paymentType = [];
      this.selectTypeListIndex = [];
      this.selectTypeListDataObject = {};
      this.selectTypeList = [];
      this.yjiaoPrice = 0;
      this.lastPlayMoney = 0;
      this.deleMoney = 0;
      this.merchantsList.forEach((item) => {
        if (item.merchants_id == id) {
          this.form.merchants_card_id = item.merchants_card_id_original;
        }
      });
    },

    setRow (data) {
      let bz = 0;
      data.forEach((item) => {
        if (!item.children || !item.children.length) {
          bz++;
        }
        if (item.children && item.children.length > 0) {
          this.setRow(item.children);
        }
      });
      if (bz == data.length) {
        data.forEach((item) => {
          item.display = "row";
        });
      }
    },
    changePaymentType (e) {
      if (!this.form.merchants_id) {
        this.$message("请选择商户");
        return;
      }
      let arr1 = e;
      let arr2 = this.selectTypeListIndex;
      var temp = arr1.concat(arr2).filter((item) => !arr2.includes(item));
      this.selectTypeListIndex = e;
      this.deleMoney = 0;
      let init = (e) => {
        let ar = [];
        e.forEach((item) => {
          if (this.paymentTypeList[item].type == 9) {
            this.paymentTypeList[item].moneyDislabel = false;
          } else {
            this.paymentTypeList[item].moneyDislabel = false;
          }
          ar.push(this.paymentTypeList[item]);
        });
        this.selectTypeList = ar;
      };

      if (temp && temp.length > 0) {
        let index = temp[0];
        let id = this.paymentTypeList[index].id;
        //临时收费
        if (this.tempMerchant) {
          if (this.sekectTypeListTempMerObj[id]) {
          } else {
            this.sekectTypeListTempMerObj[id] = {
              coupon_bill_list: null,
              property_setting: null,
              wipe_zero: {
                unit: 1,
              },
              list: [],
              other: null,
              merchants_balance: null,
              bill_key: this.paymentTypeList[index].type,
              bill_ids: [],
              total: 0.01,
              lastMoney: 0.01,
              lastMoneyCo: 0.01,
              couponTotalPrice: 0,
              couponTotal: 0,
              zhekoPrice: 0,
              coupon_ids: [],
            };
          }
          init(e);
          this.getPlayTotal1();
          return false;
        }
        //正常收费
        if (this.selectTypeListDataObject[id] && !this.tempMerchant) {
          let total = 0;
          let bill_ids = [];
          let bill_key = "";

          this.selectTypeListDataObject[id].list && this.selectTypeListDataObject[id].list.forEach((item) => {
            item.isCheck = true;
            total += item.key_pay_price * 1;
            bill_key = item.bill_key;
            bill_ids.push(item.bill_id);
          });
          this.selectTypeListDataObject[id].bill_ids = bill_ids;
          this.selectTypeListDataObject[id].bill_key = bill_key;

          this.selectTypeListDataObject[id].total = total.toFixed(2);
          init(e);
          this.calculateDiscount(this.selectTypeListDataObject[id]);
          // this.initAllMoney(this.selectTypeListDataObject[id], total);
          this.getPlayTotal();
          return false;
        }
        !this.tempMerchant && this.$request.HttpPost("/bill_offline/offlineFeeIdBill", {
          merchants_id: this.form.merchants_id,
          bill_key: this.paymentTypeList[index].type,
          fee_id: this.paymentTypeList[index].id,
        }).then((res) => {
          if (!res.data) {
            return false;
          }
          if (!res.data.list) {
            this.selectTypeListIndex.pop();
            this.$message("该收费项不存在收费！");
            return false;
          }
          res.data.fee_id = this.paymentTypeList[index].id;
          let couponTotal = 0;
          let total = 0;
          let bill_ids = [];
          let bill_key = "";
          let obj = {};
          let objIds = {};
          let coupon_id = [];
          if (res.data.coupon_bill_list) {
            // 获取自身减免
            res.data.coupon_bill_list.forEach((list) => {
              if (list.bill_id == 0) {
                res.data.derate = list;
                coupon_id.push(list.coupon_id);
                couponTotal += list.reduce_price;
              }
            })
          }
          res.data.list && res.data.list.forEach((item) => {
            item.derateTotal = 0;
            item.coupon_ids = [];
            if (res.data.coupon_bill_list) {
              res.data.coupon_bill_list.forEach((list) => {
                if (item.bill_id == list.bill_id) {
                  // item.derate = list;
                  item.derateTotal += Number(list.reduce_price);
                  item.coupon_ids.push(list.coupon_id);
                }
              })
            }
            item.pay_amount = Number(item.key_pay_price) - Number(item.derateTotal || 0) - Number(item?.discountPrice || 0);
            item.isDiscount = false;
            item.propertySetting = res.data.property_setting;
            total += item.key_pay_price * 1;
            item.isCheck = true;
            bill_ids.push(item.bill_id);
            bill_key = item.bill_key;
            if (objIds[item.contract_id]) {
              objIds[item.contract_id].push(item.bill_id);
            } else {
              objIds[item.contract_id] = [item.bill_id];
            }

            if (obj[item.contract_id]) {
              obj[item.contract_id].children.push({
                id: item.bill_id,
                label: item.contract_no,
                soData: item,
                isChecked: true,
                children: [],
              });
            } else {
              obj[item.contract_id] = {
                id: item.contract_id + "Group",
                label: item.contract_no,
                soData: item,
                isChecked: true,
                children: [
                  {
                    id: item.bill_id,
                    label: item.contract_no,
                    isChecked: true,
                    soData: item,
                    children: [],
                  },
                ],
              };
            }
          });
          res.data.defaultCheckedKeys = bill_ids;
          this.lastSelect = [...bill_ids];
          res.data.sortIds = objIds;
          res.data.treeData = Object.values(obj) || [];
          this.setRow(res.data.treeData);
          res.data.bill_key = bill_key;
          res.data.bill_ids = bill_ids;
          //total 合同应缴金额
          res.data.total = total.toFixed(2);


          if (res.data.coupon_bill_list) {
            res.data.coupon_bill_list.forEach((item) => {

            })
          }
          if (res.data.merchants_balance) {
            res.data.merchants_balance.actBalance = 0;
          } else {
            res.data.merchants_balance = {};
          }
          res.data.lastMoney = res.data.total; //lastMoney 实缴金额
          res.data.lastMoneyCo = res.data.total;
          res.data.couponTotalPrice = 0; //couponTotalPrice 总减免金额
          res.data.couponTotal = couponTotal || 0; //couponTotal 实际减免金额
          res.data.originalCoupon = couponTotal || 0; //couponTotal 原始自身减免金额
          res.data.zhekoPrice = 0; //zhekoPrice 实际折扣金额
          res.data.coupon_ids = coupon_id;

          init(e);
          // this.initAllMoney(res.data, total);
          this.selectTypeListDataObject[this.paymentTypeList[index].id] = res.data;
          this.calculateDiscount(this.selectTypeListDataObject[this.paymentTypeList[index].id]);
          console.log(this.selectTypeListDataObject);
          this.getPlayTotal();
        });
      } else {
        init(e);
        let tempVal = '-1';
        if (arr2.length > 0) {
          for (let i in arr2) {
            if (arr1.indexOf(arr2[i]) == -1) {
              tempVal = arr2[i];
            }
          }
        }
        if (tempVal != '-1') {
          delete this.selectTypeListDataObject[this.paymentTypeList[Number(tempVal)].id]
        }
        this.getPlayTotal();
        // this.$nextTick(() => {
        //   this.rowTree();
        // });
      }
    },
    async getPaymentType () {
      await this.$request
        .HttpPost("/contract_fee_setting/listAll")
        .then((res) => {
          res.data.forEach((item) => {
            if (item.type == 20) {
              item.type = 9;
            } else {
              item.type = 10;
            }
          });
          this.paymentTypeList = res.data;
        });
      // await this.$request.HttpPost("/setting/dailySetting").then((res) => {
      //   res.data.values_struct.forEach((item) => {
      //     item.contract_fee_name = item.type_name;
      //     item.id = item.children.property_setting_id;
      //     item.type = 20;
      //     this.dayFeeList.push(item.id);
      //   });
      //   this.paymentTypeList = this.paymentTypeList.concat(
      //     res.data.values_struct
      //   );
      // });
    },
    getMerchantList () {
      this.$request
        .HttpPost("/merchants/list", {
          pageNum: 1,
          pageSize: 10000,
          stall_status: 0,
          contract_status: 0,
          is_temporary: -1,
        })
        .then((res) => {
          this.merchantsList = res.data.list;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;

  .upload-img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}

/deep/ .el-tree-node__content {
  height: auto !important;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffff;
  font-size: 80px;
  z-index: 9999999;

  img {
    object-fit: contain;
  }

  .qrclose {
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
    font-size: 50px;
    text-align: center;
  }
}

.addCharge {
  .ac_input {
    border-bottom: 1px solid #528cff;
  }
}
</style>
